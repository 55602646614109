import state from "./state";
import actions from "./actions";
import thunks from "./thunks";

const store = {
  ...state,
  ...actions,
  ...thunks,
};

export default store;
